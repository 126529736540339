<template>
  <div class="checkout" :style="{ 'min-height': innerHeight }">
    <div class="content-box" >
      <div class="match-box" >
        <div class="break-box" >
          <div class="break">
              <i class="el-icon-back" @click="goBack"></i>
              <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item @click="$router.go(-1)">Menu</el-breadcrumb-item>
                  <el-breadcrumb-item >
                    <span style="color:rgb(9, 202, 106);">Check out</span>
                  </el-breadcrumb-item>
              </el-breadcrumb>
          </div>
        </div>
        <h2 class="check-title" v-if="token">Check out</h2>
        <h2 class="check-title" v-else>Login To Continue</h2>
        <img
          class="cuise-img"
          src="@/assets/images/cuiseNot.png"
          alt="nofound"
        />
      </div>
    </div>

    <h2 class="page-title" v-if="!token && !isMobile">
      Please Login To confirm with your order
    </h2>

    <div class="steps-box" >
      <el-steps :active="1" simple>
        <el-step title="Details" ></el-step>
        <el-step title="Pay" ></el-step>
        <el-step title="Order Summary" ></el-step>
      </el-steps>
    </div>
    
    <div class="main-content">
      <template v-if="(token && loginDone && !addressModal && !needFill)">
        <section class="section section-1" v-if="transType == 'delivery'">
          <AddressBoxVue v-model="nowAddressId" ref="addressBox" @addAddress="goAddress"></AddressBoxVue>
        </section>
        <section class="section section-2">
          <PaymentBoxVue v-model="paymentType" :currencySign="currencySign" :merchantId="merchantId" ref="paymentBox"></PaymentBoxVue>
        </section>
      </template>
      <section v-else class="section section-login">
        <CheckLoginBlock ref="checkLoginBlock" @loginDone="handleLoginDone" :needFill="needFill" @needFill="needFillStatus" @addressDone="addressDone" :addAddress="addressModal" :transType="transType"></CheckLoginBlock>
      </section>
      <section class="section section-3" v-show="!needFill">
        <checkoutCartBox ref="checkoutCartBox" :fixOrderBtn="fixOrderBtn" :cartList="cartList" :outAddressModal="addressModal" :merchantId="merchantId" 
        :transType="transType" v-model="form" :otherPriceInfo="otherPriceInfo" :paymentType="paymentType"
        @applyCoupon="fillCoupn" @paynow="batAddToCart" :nowAddressId="nowAddressId" @changePayment="changePayment"
        @changeTransType="changeTransType" @chooseAddress="chooseAddress" @changeBookTime="changeBookTime"></checkoutCartBox>
      </section>
    </div>

  </div>
</template>

<script>

        /* eslint-disable */
import { postGateway } from '@/request';
import AddressBoxVue from '@/components/CheckoutComps/AddressBox.vue';
import PaymentBoxVue from '@/components/CheckoutComps/PaymentBox.vue';
import CheckoutCartBox from '@/components/CheckoutComps/CheckoutCartBox.vue';
import { getCart, setCart } from '@/utils/manageCart.js'
import CheckLoginBlock from '@/components/CheckoutComps/CheckLoginBlock.vue';
import { payment } from '@/utils/payment.js';
import bus from '@/utils/bus';
import debounce from '@/utils/debounce';
export default {
  name: "NotFound",
  components: {
    AddressBoxVue,
    PaymentBoxVue,
    CheckoutCartBox,
    CheckLoginBlock
  },
  metaInfo: {
    meta: ["All Eat "],
    title: "All Eat not found",
  },
  computed: {
    isMobile() {
      return window.innerWidth < 950
    },
    currencySign() {
      return this.merInfo.currencySign || ''
    }
  },
  data() {
    return {
      token: localStorage.getItem('token'),
      transType: '',
      keywords: "",
      resultList: [],
      loading: false,
      innerHeight: 800,
      clientId:  localStorage.getItem('clientId'),
      loginDone: false,
      merchantId: null,
      nowAddressId: null,
      paymentType: null,
      cartList: [],
      // 其他价格信息
			otherPriceInfo: {
				discount: 0,
				isMerchantOff: 0,   // 是否满足优惠
				offAmount: null,
				offId: null,
				offItem: "",
				offType: "",    // discount 、 freeItem
        couponCodeDiscount: 0
			},
      deviceId: '',
      form: {
				tableware: '',
				payWay: 0,
				tipCount: 0,
				remark: '',
        tableNumber: '', //订桌
        couponCode: '', // 优惠码
				merchantDeliveryCharges: 0,  // 配送费
				serviceFee: 0, //服务费
				packagingFee: 0,  // 打包费
				pointsEarned: 0,  // 获取积分
				spendPoint: 0,   // 使用积分
				subTotal: 0,  //小计
				discount: 0,  // 折扣
				voucherAmount: 0,  // 优惠券优惠
				tip: 0,	// 小费
				total: 0,  // 总计
				deliveryAsap: 1,   // 是否立即送出
				deliveryDateStr: '',   // 配送时间
        bookTime: 'As soon as possible',
				voucherId: null,   // 下单使用的优惠券
				pointTop: 0,  // 最多能用多少积分
				isPoint: 0,  // 是否嫩使用积分
			},
      pageLoading: null,
      netCartList: [],
      addressModal: false,
      timestamp: '',
      needFill: false,
      fixOrderBtn: true,
      merInfo: {
        restaurantName: '',
        merchantId: '',
        merchantLogo: '',
        tasteRating: '',
        merchantAddr: '',
        deliveryDistance: '',
        merchantOffs: [],
        currencySign: ''
      }
    };
  },
  watch: {
    'cartList': {
      handler: function(val) {
        if(val.length*1) {
          // if(JSON.stringify(val) != JSON.stringify(getCart())) {
          //   setCart(this.merchantId, val);
          // }
          setCart(this.merchantId, val);
          console.log(val,'当前购物车数据')
          console.log(getCart(),'缓存中的数据')
          
          // this.getCartPrice(); // 计算当前购物车价格
        }
      },
      deep: true
    },
    'nowAddressId': function() {
      this.getCartPrice(); // 计算当前购物车价格
    },
    'transType': function() {
      this.getCartPrice(); // 计算当前购物车价格
    },
    'paymentType': function(val) {
      console.log(val, '支付方式变更')
    }
  },
  beforeRouteEnter (to, from, next) {
    if(from.name === 'Menu') {
      setTimeout(() => {
        bus.$emit('newIntoCheckout', true)
      }, 100);
    } else {
      setTimeout(() => {
        bus.$emit('newIntoCheckout', false)
      }, 100);
    }
    next()
  },
  created() {
    bus.$on('login',()=>{
      this.clientId = localStorage.getItem('clientId');
      this.getUserInfo();
    })
    bus.$on('newIntoCheckout',(isNew)=>{
      this.onLoad(isNew)
      setTimeout(() => {
        if(this.$refs.checkoutCartBox) {
          this.$refs.checkoutCartBox.formObj.remarks = '';
        }
      }, 200);
      console.log('执行checkout 页面进入')
    })
    this.onLoad();
  },
  mounted() {
    this.innerHeight = window.innerHeight + "px";
    this.removeNetCart();
    this.initObserver();
    this.getUserInfo();
    this.deviceId = localStorage.getItem('DeviceId')

  },
  beforeDestroy() {
    
  },
  methods: {
    getUserInfo() {
      if(!this.token) {
        return
      }
      postGateway({
        url: '/customerApp/center/getDetail',
        method: 'GET',
        data: {
          clientId: localStorage.getItem('clientId')
        }
      }).then(res=>{
        // this.userInfo = res.data;
        let nickName = res.data.firstName || res.data.nickName;
        let email = res.data.emailAddress;

         /* eslint-disable */ 
        if(!nickName || !email) {
          this.needFill = true;
          setTimeout(() => {
            this.$refs.checkLoginBlock.nextTab(2)
          }, 500);
        }
        console.log(res.data, '获取用户信息')
      })
      .catch()
    },
    initObserver() {
      if(this.observe) {
          this.observe.disconnect(); // 关闭所有监听
          this.observe = null;
      }
      const callback = entries => {
          entries.forEach(entry => {
            
              if(entry.intersectionRatio > 0) {
                debounce(()=>{
                  this.fixOrderBtn = false;
                }, 500)
              } else {
                debounce(()=>{
                  this.fixOrderBtn = true;
                }, 500)
              }
          });
      };
      this.observer = new IntersectionObserver(callback, {
          rootMargin: '0px 0px -50px 0px', 
      });
      this.observer.observe(document.querySelector('.footer'))
    },
    getMerchantInfo() {
      let param = {
        merchantId: this.merchantId,
        type: this.transType == 'delivery'?1:this.transType == 'pickup'?2:this.transType == 'dinein'?3: 0,
      }
      postGateway({
        url: '/customerApp/merchantDetail/getMerchantDetail',
        method: 'GET',
        data: param
      })
      .then(res=>{
        this.merInfo = res.data
      })
    },
    onLoad(isFirst=true) {
      let { bookTime, deliveryAsap, deliveryDateStr, merchantId, transType, tableNumber } = this.$route.query;
      this.merchantId = merchantId;
      this.getMerchantInfo();
      if(isFirst) {
        this.form.bookTime = bookTime
        this.form.deliveryAsap = deliveryAsap
        this.form.deliveryDateStr = deliveryDateStr
        this.form.tableNumber = tableNumber;
        this.transType = transType;
      }
      
      // 从缓存读取购物车内容 // 接口数据进来了之后刷新该数据
      if(this.merchantId) {
        let list = getCart(this.merchantId)
        this.cartList = list.map(x=>({
          ...x,
          clientId: this.clientId
        }))
      }

      console.log(this.cartList,'cartList - 执行更新购物车', merchantId);
      this.getCartPrice();

      // 页面初始化的情况下有 clientId 认为是登录完毕
      if(this.clientId) {
        this.loginDone = true;
      }
    },
    needFillStatus(val) {  // 用户需要补充信息
      this.needFill = val;
    },
    closeAddress() {
      this.addressModal = false;
    },
    goAddress() {
      console.log('执行了这里')
      this.addressModal = true;
    },
    changePayment(val) {
      this.paymentType = val;
    }, 
    changeTransType(val) {
      this.transType = val;
    },
    changeBookTime(obj) {
      let { bookTime, deliveryAsap, deliveryDateStr } = obj;
      this.form.bookTime = bookTime;
      this.form.deliveryAsap = deliveryAsap;
      this.form.deliveryDateStr = deliveryDateStr;      
    },
    chooseAddress(address) {
      this.nowAddressId = address.id;
    },
    // 清空线上购物车
    removeNetCart() {
      if(!localStorage.getItem('token')) {
        return Promise.resolve();
      }
      return postGateway({
          url: '/customerApp/cart/cleanCart',
          data: {
            merchantId: this.merchantId,
            clientId: localStorage.getItem('clientId')
          }
      })
      .then(res => {
          console.log(res, '清除购物车成功')
      })
      .catch(err => {
          console.log(err, '清除购物车失败')
      })
    },
    // 被通知登录完成
    handleLoginDone() {
      this.loginDone = true;
      this.addressModal = false;
      this.token = localStorage.getItem('token');
      this.getCartPrice(); // 计算当前购物车价格
      this.removeCartPreOrder();  // 清空购物车
    },
    removeCartPreOrder() {
      if(!localStorage.getItem('clientId')) {
          this.showOrderLoading = false;
          return Promise.resolve(true)
      }
      let param = {
          merchantId: this.merchantId * 1,
          clientId: localStorage.getItem('clientId')
      }
      return postGateway({
          url: '/customerApp/cart/cleanCart',
          data: param
      })
      .then(res => {
          this.showOrderLoading = false;
          console.log(res, '清除购物车成功')
      })
      .catch(err => {
          this.showOrderLoading = false;
          this.$message.warning('Net Error')
          console.log(err, '清除购物车失败')
      })
    },
    // 添加地址完成
    addressDone() {
      this.addressModal = false;
    },
    fillCoupn(obj) {
      let { remarks, points, tip, couponCode } = obj;
      this.form.remark = remarks;
      this.form.points = points;
      this.form.tip = tip;
      this.form.couponCode = couponCode;
      this.getCartPrice()
    },
    // 获取当前选择内容金额  
    getCartPrice() {  // 不需要登陆
      let param = {
        merchantId: this.merchantId,
        foodCartList: this.cartList,
        transType: this.transType,
        couponCode: this.form.couponCode,
        requestFrom: this.isMobile?'H5':'web',
				spendPoint: this.form.points,
        tip: this.form.tip,
        clientId: this.clientId || null,
        requestFrom: 'web'
      }

      if(this.nowAddressId) {
        param.addressId = this.nowAddressId || null
      }

        postGateway({
            url: '/customerApp/cart/getBatMerchantCart',
            data: param
        }).then(res=>{
          console.log(res,'购物车价格')
          this.form.packagingFee = res.data.packagingFee?res.data.packagingFee.toFixed(2):'0.00';
          this.form.pointsEarned = res.data.pointsEarned;
          
          if(!this.form.spendPoint) {
            this.form.spendPoint = res.data.spendPoint?res.data.spendPoint*1:0;
          }
          
          this.form.serviceFee = res.data.serviceFee?res.data.serviceFee.toFixed(2):'0.00';
          
          this.form.merchantDeliveryCharges = res.data.merchantDeliveryCharges?res.data.merchantDeliveryCharges.toFixed(2):'0.00'  // 配送费
          this.form.subTotal = res.data.subTotal?res.data.subTotal.toFixed(2):'0.00';  //小计
          this.form.discount = res.data.discount?res.data.discount.toFixed(2):'0.00';  // 折扣
          this.form.voucherAmount = res.data.voucherAmount?res.data.voucherAmount.toFixed(2):'0.00';  // 优惠券优惠
          
          if(!this.form.tip) {
            this.form.tip = res.data.tip?(res.data.tip*1).toFixed(2):'0.00';  // 小费
          }
          
          
          this.form.total = res.data.total;  // 总计
          this.form.maxPoints = res.data.pointTop?res.data.pointTop*1:0;   // 最多用多少积分
          this.form.pointTop = res.data.pointTop?res.data.pointTop*1:0;   // 最多用多少积分
          this.form.isPoint = res.data.isPoint
          this.form.isOverDelivery = res.data.isOverDelivery
          this.form.pointLimit = res.data.pointLimit;
          
          // 处理折扣
          this.otherPriceInfo = res.data;

          // foodcart list 购物车内容更新
          try {
            let foodCartList = res.data.list;
            // let localCartList = JSON.parse(JSON.stringify(this.cartList));
            // foodCartList.forEach(ele=>{
            //   localCartList.forEach(food=>{
            //     if(this.judgeFoodSame(ele, food) && food.price*1 != ele.price*1 ) {
            //       console.log(food.price, ele.price, 'ele.price')
            //       food.price = ele.price;
            //     }
            //   })
            // })
            if(foodCartList && foodCartList.length) {
              this.cartList = foodCartList;
            }
            
          } catch (error) {
            console.log('更新购物车内容报错')
          }
          // this.cartList = foodCartList.map()
          // this.cartList = foodCartList;
        })
        .catch(err=>{
          bus.$emit('couponLoadDone')
          this.$message.warning(err.msg || 'Net Error');
          console.log(err, '获取当前购物车价格失败')
        })
    },
    judgeFoodSame(ele,food) {
      let result = false;
      if( ele.itemId == food.itemId && ele.chooseItems == food.chooseItems && ele.subItems == food.subItems && (JSON.stringify(ele.subItems) === JSON.stringify(food.subItems))) {
        result = true;
      }
      return result;
    },
    //  freeItem 加入购物车
    addFreeItemToCart() {
      let freeItemList = this.cartList.filter(x=>(x.offId||x.freeitemFlag)).map(x=>{
        return {
            ...x,
          merchantId: this.merchantId,
          freeitemFlag: 1,
          clientId: localStorage.getItem('clientId')
        }
      })
      return postGateway({
        url: "/customerApp/cart/batAddFreeItemAppCart",
        data: freeItemList
      }).then(res=>{
        console.log(res,'批量加入购物车回参')
      })
    },
    // 批量加入购物车
    async batAddToCart(obj) {
        this.pageLoading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });

        await this.removeNetCart();
        
        postGateway({
          url: '/customerApp/cart/addAppCartBat',
          data: this.cartList.filter(x=>!(x.offId||x.freeitemFlag)).map(x=>{
            return {
              ...x,
              merchantId: this.merchantId,
              clientId: localStorage.getItem('clientId')
            }
          })
        }).then(res=>{
          console.log(res,'加入购物车成功')

          // 将freeItem 加入购物车
          this.addFreeItemToCart().then(()=>{
            // 查询购物车
            this.getCartInfo(obj);
          })

        })
        .catch(err=>{
          this.pageLoading.close();
          this.$message.warning(err.msg || 'Net Error');
          console.log(err,'加入购物车失败')
        })
    },
    // 立即下单 - 之前需要先吧物品加入购物车
    paynow(obj) {
      /* eslint-disable */
      let {remarks, points, couponCode } = obj;
      let {
					serviceFee,packagingFee,spendPoint,merchantDeliveryCharges,subTotal,discount,voucherAmount, tableNumber
				} = this.form;

        this.form.remark = remarks;
        this.form.points = points;
        this.form.couponCode = couponCode;
				
				let { offAmount, offId, offItem,offType } = this.otherPriceInfo;

        console.log(this.nowAddressId, '确实执行了这里')

        if(this.transType == 'delivery' && !this.nowAddressId) {
          this.$message.warning('Please Choose Address')
          return
        }
				
				let param = {
					addressId: this.nowAddressId || null,  //地址id
					cartIds: this.netCartList.map(x=>x.id),
					transType: this.transType,
					clientId: this.clientId * 1,
					paymentType: this.paymentType,  // 支付方式
					merchantId: this.merchantId * 1,
					deliveryAsap: this.form.deliveryAsap,
          couponCode: this.form.couponCode,
					deliveryDateStr: this.form.deliveryDateStr?this.$moment(new Date(this.form.deliveryDateStr).valueOf()).format('YYYY-MM-DD HH:mm'):'',
					voucherId: this.form.voucherId,  // 下单使用的优惠券
					remark: this.form.remark + ` - ${localStorage.getItem('userFrom') || ''}`,  //下单备注
					spendPoint: this.$encryptByPublicKey(spendPoint.toString()),
					total: this.$encryptByPublicKey((this.form.total*1 + this.form.tip*1  - this.form.spendPoint/100).toString()),
					serviceFee: this.$encryptByPublicKey(serviceFee.toString()),
					packagingFee: this.$encryptByPublicKey(packagingFee.toString()),
					tip: this.$encryptByPublicKey(this.form.tip.toString()),  // 小费
					subTotal: this.$encryptByPublicKey(subTotal.toString()),
					merchantDeliveryCharges: this.$encryptByPublicKey(merchantDeliveryCharges.toString()),
					discount,
          tableNumber,
					voucherAmount,
					offAmount, offId, offItem,offType,
					requestFrom: this.isMobile?'H5':'web',
					deviceId: this.deviceId || ''
				}
        console.log(param, '下单参数')
        
        postGateway({
          url: '/customer/order/createOrderSecurity',
          data: param
        }).then(res=>{
          if(res.data.code && ![200,50022,50024,50023,50030].includes(res.data.code*1)) {
            this.$message.error('Error')
						return
					}
					
					if(!res || !res.data.orderId || !res.data.price) {
						this.$message.error('Error')
						return
					}

          // 下单成功回参
          let pa = {
						orderId: res.data.orderId,
						amount: res.data.price,
            merchantId: this.merchantId
					}

          this.$refs.checkoutCartBox.confirmModal = false;
          
          // 跳转支付
          payment(this.paymentType ,pa);
          
          setTimeout(() => {
            this.pageLoading.close();
          }, 2000);

          console.log(res.data, '下单成功')
        })
        .catch(err=>{
          this.pageLoading.close();
          this.$message.warning(err.msg || 'Net Error')
        })
    },
    // 获取下单金额 并下单  需要登录
    getOrderAmount(obj) {
      let param  = {
				clientId: this.clientId * 1,
				addressId: this.nowAddressId || null,
				ids: this.netCartList.map(x=>x.id),  // 购物车内容
				merchantId: this.merchantId * 1,
				voucherId: null,  // 老优惠券
				transType: this.transType, // 暂时写死
        couponCode: this.form.couponCode,  // 新版优惠券码
        requestFrom: 'web',
				spendPoint: this.form.points,
        tip: this.form.tip
			};
      postGateway({
        url: '/customerApp/cart/getChooseCartDetail',
        method: 'GET',
        data: param
      }).then(res=>{
        this.form.packagingFee = res.data.packagingFee?res.data.packagingFee.toFixed(2):'0.00';
				this.form.pointsEarned = res.data.pointsEarned;
				
				if(!this.form.spendPoint) {
					this.form.spendPoint = res.data.spendPoint?res.data.spendPoint*1:0;
				}
				
				this.form.serviceFee = res.data.serviceFee?res.data.serviceFee.toFixed(2):'0.00';
				
				this.form.merchantDeliveryCharges = res.data.merchantDeliveryCharges?res.data.merchantDeliveryCharges.toFixed(2):'0.00'  // 配送费
				this.form.subTotal = res.data.subTotal?res.data.subTotal.toFixed(2):'0.00';  //小计
				this.form.discount = res.data.discount?res.data.discount.toFixed(2):'0.00';  // 折扣
				this.form.voucherAmount = res.data.voucherAmount?res.data.voucherAmount.toFixed(2):'0.00';  // 优惠券优惠
				
				if(!this.form.tip) {
					this.form.tip = res.data.tip?(res.data.tip*1).toFixed(2):'0.00';  // 小费
				}
				
				
				this.form.total = res.data.total;  // 总计
				this.form.maxPoints = res.data.pointTop?res.data.pointTop*1:0;   // 最多用多少积分
				this.form.pointTop = res.data.pointTop?res.data.pointTop*1:0;   // 最多用多少积分
				this.form.isPoint = res.data.isPoint
				this.form.isOverDelivery = res.data.isOverDelivery
				this.form.pointLimit = res.data.pointLimit;
				
				// 处理折扣
				this.otherPriceInfo = res;
				
				// 处理
				console.log('其他信息下单商家信息', this.form)

        // 下单
        this.paynow(obj);
      })
      .catch(err=>{
        console.log(err,'获取购物车报错');
        this.$message.warning(err.msg || 'Net Error');
        this.pageLoading.close();
      })
    },
    // 获取用户在该商家下的购物车内容
    getCartInfo(obj) {
      postGateway({
          url: '/customerApp/cart/getMerchantCat',
          method: 'GET',
          data: {
            merchantId: this.merchantId,
            clientId: this.clientId,
          }
      }).then(res=>{
        this.netCartList = res.data;  //获取购物车信息
        console.log(this.netCartList, '线上购物车信息')

        this.getOrderAmount(obj);
      })
      .catch(err=>{
        console.log(err,'获取购物车报错')
        this.pageLoading.close();
      })
    },
    goBack() {
      this.$router.go(-1)
    },
  },
};
</script>

<style lang="scss" scoped>
$greenColor: rgb(9, 202, 106);
$mainColor: #aa0bc0;
.checkout {
  min-height: 100vh;

  .content-box {
    position: relative;
    background-image: url('../assets/images/notfoundBg.png');
    background-size: cover;
    background-position: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 360px;
    .match-box {
      position: relative;
      height: 100%;
    }
    .break-box {
      position: absolute;
      box-sizing: border-box;
      top: 48px;

      .break {
          height: 370px;
          height: 44px;
          display: inline-flex;
          align-items: center;
          padding: 20px;
          border-radius: 22px;
          background-color: #0A191E;
          color: #FFFFFF;
          .el-icon-back {
              color: #2680ED;
              font-size: 22px;
              margin-right: 10px;
              cursor: pointer;
          }
          :deep(.el-breadcrumb__inner) {
              font-weight: 600;
              color: #fff;
          }
          :deep(.el-breadcrumb__separator) {
              color: #fff;
          }
      }
  }
  .check-title {
    position: absolute;
    box-sizing: border-box;
    transform: translateY(0)!important;
    bottom: 48px;
    font-weight: 700;
    font-size: 70px;
    color: #fff;
    margin: 0;
  }
  .cuise-img {
      height: 259px;
      width: 370px;
      position: absolute;
      z-index: 1;
      right: 6%;
      top: 0px;
    }
    // tips
    .title-tips {
      font-family: "Poppins";
      font-size: 50px;
      color: #fff;
      font-weight: bold;
    }

    .title-desc {
      font-family: "Poppins";
      margin-top: 10px;
      font-size: 20px;
      color: #fff;
      letter-spacing: 0.5px;
    }
  }

  .page-title {
    display: flex;
    justify-content: center;
    font-size: 24px;
    color: #000;
    font-weight: 600;
    margin: 25px 0 10px;
  }

  .steps-box {
    margin: 20px auto;
    width: 1600px;
  }

  .main-content {
    margin: 20px auto;
    width: 1600px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .section {
      display: flex;
      flex-direction: column;
      margin-right: 3%;
    }
    .section-login {
      width: 780px;
    }
    .section-1 {
      width: 538px;
    }
    .section-2 {
      width: 476px;
    }
    .section-3 {
      width: 530px;
    }


  }

}

button {
  border: none;
}
input {
  border: none;
  outline: none;
  font-weight: bold;
  font-size: 20px;
}

// 处理屏幕兼容性 < 1600px

@media screen and (min-width:1300px) and (max-width:1600px) {
  .checkout {
    .main-content {

      .section {
        width: 400px;
      }
      .section-3 {
        width: 500px;
      }
      .section-login {
        width: 580px;
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .checkout {
    .content-box {
      height: auto;
      .match-box {
        width: 100vw;
        .break-box, .cuise-img {
          display: none;
        }
        .check-title {
          position: relative;
          padding: 15px;
          font-size: 30px;
          transform: translateY(0);
          top: 0;
        }
      }
    }
    .page-title {
      font-size: 14px;
      justify-content: flex-start;
      padding-left: 15px;
      margin: 17px 0 10px;
    }
    .steps-box {
      width: 100vw;
      zoom: 0.75;
      display: none;
    }
    .main-content {
      width: 100vw;
      flex-direction: column;
      align-items: center;
      margin: 10px auto;
      .section {
        width: 94vw;
        margin: 0;
      }
      .section-3 {
        margin-top: 10px!important;
      }
    }
  }
}

</style>
