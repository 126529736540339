<template>
    <div class="menu">
        <div class="header" 
            :style="{
                'background-image': `url(${isMobile?(mobileBgImage):(merInfo.webBgImg?merInfo.webBgImg:require('@/assets/images/notfoundBg.png'))})`,
                'background-size': `${isMobile?'cover':(merInfo.bgImg?'unset':'cover')}`
            }">
            <div class="match-box">
                <div class="break-box" >
                    <div class="break">
                        <i class="el-icon-back" ></i>
                        <el-breadcrumb separator-class="el-icon-arrow-right">
                            <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                            <el-breadcrumb-item :to="{ path: '/browse' }">Browse</el-breadcrumb-item>
                            <el-breadcrumb-item>
                                <span style="cursor:default;font-weight: 600;color:#09CA6A;font-size: 16px;">{{ slug }}-menu</span>
                            </el-breadcrumb-item>
                        </el-breadcrumb>
                    </div>
                </div>
                <div class="mer-box">
                    <!-- <div class="info-box" >
                        <div class="l-box">
                            <span class="img-block" >
                                <viewer :images="[merInfo.merchantLogo]" >
                                    <img :src="merInfo.merchantLogo" alt="">
                                </viewer>
                            </span>
                            <span class="status-tag" v-if="!merInfo.storeCloseStatus" >Open</span>
                            <span class="status-tag status-tag-close" v-else >Closed</span>
                        </div>
                        <div class="r-box">
                            <h2 class="title" :style="{'font-size': titleSize}">{{ merInfo.restaurantName }}
                            </h2>
                            <div class="info-item" v-if="merInfo.merchantAddr">
                                <i class="el-icon-location"></i>
                                <span>{{ merInfo.merchantAddr }}</span>
                            </div>
                            <div class="info-item" v-if="merInfo.tasteRating">
                                <el-rate style="position: relative;top:2px;" v-model="merInfo.tasteRating" :disabled="true" :colors="['#aa0bc0','#aa0bc0','#aa0bc0']"
                                        disabled-void-color="#aa0bc0"></el-rate>
                                <span>
                                    {{ merInfo.tasteRating }} 
                                    <a style="color:#ffffff" :href="'/reviews?merInfoId='+merInfo.merchantId+'&slug='+slug">({{ merInfo.reviewCount || 0 }}) reviews</a>
                                </span>
                            </div>
                            <div class="info-item" >
                                <div class="btn" @click="focusMerchant" v-if="isMobile">
                                    <img v-if="merInfo.isFavorite" src="@/assets/icon/heart.png" alt="">
                                    <img v-else src="@/assets/icon/heart_fill.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="focus-btn-box" v-if="!isMobile">
                        <div class="btn" @click="focusMerchant">
                            <img v-if="merInfo.isFavorite" src="@/assets/icon/heart.png" alt="">
                            <img v-else src="@/assets/icon/heart_fill.png" alt="">
                            <span>Wishlist</span>
                        </div>
                        <!-- <div class="btn" v-if="false">
                            <i class="el-icon-picture-outline"></i>
                            <span>View Photos</span>
                        </div>
                        <div class="btn mobile-show" @click="booTable">
                            <i class="el-icon-edit-outline"></i>
                            <span>Book a Table</span>
                        </div>
                        <div class="btn mobile-show" @click="viewInfo">
                            <i class="el-icon-stopwatch"></i>
                            <span>View Restaurant Information</span>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="card-box" >
            <div class="info-box" >
                <div class="l-box">
                    <span class="img-block" >
                        <viewer :images="[merInfo.merchantLogo]" >
                            <img :src="merInfo.merchantLogo" alt="">
                        </viewer>
                    </span>
                    <span class="status-tag" v-if="!merInfo.storeCloseStatus" >Open</span>
                    <span class="status-tag status-preorder" v-else-if="merInfo.storeCloseStatus && merInfo.preOrder==1" >
                        <i class="el-icon-time" ></i>
                        Pre-Order
                    </span>
                    <span class="status-tag status-tag-close" v-else >Closed</span>
                </div>
                <div class="r-box">
                    <h2 class="title" :style="{'font-size': titleSize}">{{ merInfo.restaurantName }}
                    </h2>
                    <div class="info-item" style="align-items: flex-start;" v-if="merInfo.merchantAddr">
                        <i class="el-icon-location"></i>
                        <span>{{ merInfo.merchantAddr }}</span>
                    </div>
                    <!-- <div class="info-item" style="color: #333;margin-top: 15px;" >
                        Phone: <a :href="'tel:'+merInfo.restaurantPhone" style="margin-left: 10px;font-size: 15px;text-decoration: underline;" >{{ merInfo.restaurantPhone }}</a>
                    </div> -->
                    <!-- <div class="info-item" style="color: #333;" >
                        <span class="status-tag-info" v-if="!merInfo.storeCloseStatus" >Open</span>
                        <span class="status-tag-info status-preorder-info" v-else-if="merInfo.storeCloseStatus && merInfo.preOrder==1 && todayOpenTime" >
                            <i class="el-icon-time" style="color:#007bf9;font-size: 15px;margin-left: -7px;margin-right: 3px;"  ></i>
                            Pre-Order - Openning at {{ todayOpenTime }}
                        </span>
                        <span class="status-tag-info status-tag-close-info" v-else >Closed</span>
                        
                    </div> -->
                    <div class="info-item" style="color: #333;" >
                        {{ merInfo.cuisines }}
                    </div>
                    <div class="info-item" v-if="merInfo.tasteRating">
                        <!-- <el-rate style="position: relative;top:2px;" v-model="merInfo.tasteRating" :disabled="true" :colors="['#aa0bc0','#aa0bc0','#aa0bc0']"
                                disabled-void-color="#aa0bc0"></el-rate> -->
                        <el-rate style="position: relative;top:2px;" v-model="merInfo.tasteRating" :disabled="true" 
                                disabled-void-color="#aa0bc0"></el-rate>
                        <span class="reviews-info">
                        {{ merInfo.tasteRating }} 
                        <a :href="'/reviews?merInfoId='+merInfo.merchantId+'&slug='+slug">({{ merInfo.reviewCount || 0 }}) reviews </a>
                        </span>
                    </div>
                    <div class="info-item" >
                        <div class="btn" @click="focusMerchant" v-if="isMobile">
                            <img v-if="merInfo.isFavorite" src="@/assets/icon/heart.png" alt="">
                            <img v-else src="@/assets/icon/heart_fill.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <!-- 移动版的折扣显示 -->
            <div class="info-item" v-if="isMobile">
                <MerchantOffMobile :info="merInfo" ></MerchantOffMobile>
            </div>

            <!-- 骨架屏幕 -->
            <div class="card-box-mask" v-if="pageLoading" >
                <el-skeleton-item variant="image" animated style="width: 160px; height: 160px;" />
                <div style="flex: 1;padding-left: 40px;" >
                    <el-skeleton :rows="6" animated />
                </div>
            </div>
        </div>
        <!-- 移动版显示按钮 -->
        <div class="focus-btn-box" v-if="isMobile">
            <div class="btn mobile-show" @click="booTable" v-if="merInfo.dinein && merInfo.merchantTableBooking">
                <i class="el-icon-edit-outline"></i>
                <span>Book a Table</span>
            </div>
            <div class="btn mobile-show" @click="viewInfo">
                <i class="el-icon-stopwatch"></i>
                <span>View Restaurant Information</span>
            </div>
        </div>

        <!-- 菜单分类 -->
        <div class="menu-main-box">
            <!-- 背景部分 -->
            <!-- <div class="menu-bg menu-bg-1" >
                <img src="@/assets/menu/menu_bg_1.png" alt="">
            </div>
            <div class="menu-bg menu-bg-2" >
                <img src="@/assets/menu/menu_bg_2.png" alt="">
            </div>
            <div class="menu-bg menu-bg-3" >
                <img src="@/assets/menu/menu_bg_3.png" alt="">
            </div> -->
            <!-- 类型部分 -->
            <div class="category-box">
                <CategoryBox :categoryList="categoryList" @searchResult="searchResult" @clearSearchResult="clearSearchResult"
                :info="merInfo" v-model="nowCateId" ></CategoryBox>
                <div class="category-box-mask" v-if="pageLoading" >
                    <el-skeleton-item v-if="!isMobile" variant="image" animated style="width: 160px; height: 160px;" />
                    <el-skeleton-item v-else style="height: 80%;" animated />
                </div>
            </div>
            <!-- 菜单部分 -->
            <div class="menu-box">
                <MenuBox ref="menuBox" :pageLoading="pageLoading" v-model="showSearchCover" :cartList="cartList" :foodList="foodList" @addCart="addCart" :info="merInfo" :categoryList="categoryList" :currencySign="merInfo.currencySign" ></MenuBox>
            </div>

            <!-- 购物车部分 -->
            <div class="cart-box">
                <CartBox ref="cartBoxDom" v-model="cartList" @showTransTypeModal="showTransTypeModal" @cartChangeQty="cartChangeQty" 
                    @handeShowMask="handeShowMask"  :dateOptions="multiSelector" @changeTransType="changeTransType" 
                    :trans="transType" :info="merInfo" :merchantId="merchantId" :tableBookMultiSelector="tableBookMultiSelector"></CartBox>
            </div>

        </div>

        <div class="clear"></div>
        <br />

        <div class="cover-mask" v-if="showMask"></div>

        <!-- 回到顶部按钮 -->
        <el-backtop :bottom='48' :right='140'>
            <i class="el-icon-caret-top"></i>
        </el-backtop>

        <!-- transtype弹窗 -->
        <el-dialog
            :visible.sync="showModal"
            :width="isMobile?'90%':'400px'"
            :close-on-click-modal="false"
            top="30vh"
            :before-close="handleClose"
            :show-close="false"
            :modal-append-to-body="false"
            custom-class="transtype-modal">
            <div slot="title" class="modal-title">
                <div class="gray-line" ></div>
                <div>Choose The Order Mode </div>
            </div>
            <div class="modal-content trans-modal" >
                <div class="trans-drawer-box">
                    <ul>
                        <li :class="['trans-btn', { 'active': transType==item.value }]" v-for="item in transTypeList" :key="item.value" 
                            @click="chooseTransType(item)">
                            <span class="name" >{{ item.name }}</span>
                            <i v-if="item.value == 'delivery'" class="iconfont icon-waimai"></i>
                            <i v-if="item.value == 'pickup'" class="el-icon-s-goods"></i>
                            <i v-if="item.value == 'dinein'" class="el-icon-dish"></i>
                        </li>
                    </ul>
                </div>
                <div class="img-box" >
                    <img :src="require(`@/assets/menu/modal_${transType}.png`)" :alt="transType">
                    <div class="color-bg" ></div>
                </div>
            </div>
            <span slot="footer" class="modal-footer">
                <button style="background-color: #09ca6a;font-size:20px;cursor:pointer;" @click="handleClose">
                    <span>OK</span>
                </button>
            </span>
        </el-dialog>

        <!-- pageLoading -->
        <!-- <PageLoading v-model="pageLoading"></PageLoading> -->

        <!-- app 下载提示弹窗 -->
         <DownloadAppModal v-model="showDownloadModal"  :seconds="15000" ></DownloadAppModal>

    </div>
</template>

<script>
/* eslint-disable */ 
import { postGateway } from '@/request';
import pickerList from "@/utils/getPickTimeList";
import MenuBox from '@/components/MenuComps/MenuBox.vue';
import CartBox from '@/components/MenuComps/CartBox.vue';
import CategoryBox from '@/components/MenuComps/CategoryBox.vue';
import PageLoading from '@/components/PageLoading.vue';
import MerchantOffMobile from '@/components/MenuComps/MerchantOffMobile.vue';
import DownloadAppModal from "@/components/DownloadAppModal.vue";
import { setCart, getCart } from '@/utils/manageCart';
import debounce from '@/utils/debounce';
// import bus from '@/utils/bus';
export default {
    components: {
        MenuBox,
        CartBox,
        CategoryBox,
        PageLoading,
        MerchantOffMobile,
        DownloadAppModal
    },
    metaInfo: {
        meta: ["All Eat "],
        title: "All Eat",
        description: []
    },
    data() {
        return {
            merchantId: null,
            clientId:  localStorage.getItem('clientId'),
            transType: 'pickup',
            pageLoading: true,
            slug: '',
            showDownloadModal: false,
            merInfo: {
                restaurantName: '',
                merchantId: '',
                merchantLogo: '',
                tasteRating: '',
                merchantAddr: '',
                deliveryDistance: '',
                merchantOffs: [],
                freeItemoffs: [],  // 免费菜折扣
            },
            showMask: false,
            categoryList: [],
            foodList: [],
            nowCateId: '',
            oldCateId: 0,  // 缓冲区 cateID
            keyword: '',
            cartList: [],
            foodTimer: null,
            multiSelector: [
				{
					value: '',
					label: 'Today',
					children: [
                        {
							value: 'asap',
							label: 'As soon as possible'
						}
					]
				},
				{
					value: '',
					label: 'Tomorrow',
					children: []
				},
			],
            tableBookMultiSelector: [
            {
					value: '',
					label: 'Today',
					children: []
				},
				{
					value: '',
					label: 'Tomorrow',
					children: []
				},
            ],
            observer: null,
            showSearchCover: false,
            showModal: false,
            transTypeBeingChosed: false,
            transTypeList: [],
            cartTotalPrice: 0,
            todayOpenTime: ''
        }
    },
    beforeRouteEnter (to, from, next) {
        console.log(to,'to');
        if(to.fullPath[to.fullPath.length-1] === '/') {
            let path = to.fullPath.slice(0, to.fullPath.length-1)
            next({
                path,
                meta: {
                    keepAlive: true,
                    noFixedHeader: true
                }
            })
        } else {
            next()
        }
    },
    computed: {
        mobileBgImage() {
            let url = this.merInfo.bgImg;
            if(url && url.includes('h5banner.png')) {
                url = this.merInfo.webBgImg || url;
            }
            return url
        },
        titleSize() {
            let size = (22 / this.merInfo.restaurantName.length  * 50);
            return size>50?50:size + 'px';
        },
        isMobile() {
            return window.innerWidth < 950
        },
        currencySign() {
            return this.merInfo.currencySign || ''
        },
        totalPrice() {  // 当前购物车总价
            let arr = this.cartList.map(x=>x.price).length?this.cartList.map(x=>(x.price*x.qty).toFixed(2)):[0]
            let res = arr.reduce((a,b)=>a*1+b*1) * 1;
            return res.toFixed(2)
        }
    },
    watch: {
        'nowCateId': function(val) {
            let index = this.categoryList.map(x=>x.catId).indexOf(val);
            if(index > -1) {
                this.getMenuList(index);
            }
        },
        '$route.params.pathMatch': function(val) {
            if(this.slug != val && val) {
                console.log(val, '页面变了')
                this.reStartData();
                this.initMerchant();
                this.transTypeBeingChosed = false;
            } else {
                console.log('没变啊')
            }
        },
        'cartList': {
            handler: function(val) {
                debounce(()=>{
                    setCart(this.merchantId, val); //设置购物车
                    this.getCartPrice(); // 计算当前购物车价格
                }, 500)
            },
            deep: true
        },
        'transType': function() {
            this.getMerchantInfo()
        }
    },
    created() {
        this.pageLoading = true;
        this.initMerchant();
        this.initObserver(); //设置滚动监听
        this.tryGetUserFrom();
    },
    mounted() {

    },
    beforeDestroy() {
        this.pageLoadingClose();
    },
    methods: {
        tryGetUserFrom() {
            let query = this.$route.query;
            if(query.f) {
                localStorage.setItem('userFrom',query.f);
                this.uploadError();
            }
        },
        uploadError() {
            try {
                postGateway({
                    url: '/customerApp/order/submitPaylog',
                    method: "POST",
                    data: {
                        cardPayInfo: `用户来自tiktok - ${new Date().valueOf()}`,
                    }
                }).then((res) => {
                    console.log(res, "正常数据上报");
                })
                .catch((err) => {
                    console.log("正常数据上报");
                });
                
            } catch (e) {
                console.log("数据上报错误处理");
            }
        },
        getAllMenu() {
            postGateway({
                url: `/customerWeb/merchantDetail/getMerchantFoodAll`,
                method: "GET",
                data: {
                    merchantId: this.merchantId,
                    pageNum: 1,
                    pageSize: 6
                }
            }).then(res=>{
                console.log(res.data,'获取商家菜单信息----new')
            })
            .catch(err=>{
                this.$message.error(err)
            })
        },
        showTransTypeModal() {
            this.showModal = true;
        },  
        chooseTransType(item) {
            this.transTypeBeingChosed = true;
            this.transType = item.value;
        },
        handleClose() {
            this.showModal = false;
            this.$refs.cartBoxDom.orderNow();
        },
        scrollToTarget() {
            let dom = document.querySelector('.menu-main-box');
            let target = dom.offsetTop+50;
            if(window.innerWidth < 950) {
                target = dom.offsetTop;
            }
            document.body.scrollTop = document.documentElement.scrollTop  = target;
        },
        searchResult(data) {
            this.showSearchCover = true;
            this.scrollToTarget();
            if(data == 'loading') {
                this.$refs.menuBox.searchLoading = true;
            } else {
                this.$refs.menuBox.searchResult = data.map(x=>({
                    ...x,
                    qty: 0
                }))
                this.$refs.menuBox.searchLoading = false;
            }
            
        },
        clearSearchResult() {
            this.showSearchCover = false;
            this.$refs.menuBox.searchLoading = false;
            this.$refs.menuBox.searchResult = [];
        },
        changeTransType(val) {
            this.transTypeBeingChosed = true;
            this.transType = val;
        },
        pageLoadingOpen() {
            this.pageLoading = true;
        },
        pageLoadingClose() {
            this.pageLoading = false;
        },
        initObserver() {
            if(this.observe) {
                this.observe.disconnect(); // 关闭所有监听
                this.observe = null;
            }
            const callback = entries => {
                entries.forEach(entry => {
                    if(entry.intersectionRatio > 0) {

                        this.nowCateId = entry.target.dataset.cateid * 1;

                        // category滚动到指定内容区域
                        this.$nextTick(()=>{
                            let dom = document.querySelector('.now-cate');
                            let scrollBox = document.querySelector('.cate-box')
                            let target = dom.offsetLeft - 10;
                            scrollBox.scrollLeft = target;
                        })
                    } else {
                        entry.target.classList.remove('show-active')
                    }
                });
            };
            this.observer = new IntersectionObserver(callback, {
                rootMargin: '0px 0px -50px 0px', 
            });
        },
        booTable() {
            this.$refs.cartBoxDom.showMerchantBook();
        },
        viewInfo() {
            this.$refs.cartBoxDom.showMerchantAddress();
        },
        handeShowMask(val) {
            this.showMask = val;
        },
        // 菜单同步购物车变动同步列表数量, 参数是购物车列表
        cartChangeQty(food) {
            this.reSetMenuFoodCount(food)
            console.log(food,'购物车食品数量变更')
        },
        // 获取 FreeItem 信息
        getFreeItemInfo(obj) {
            let totalPrice = obj.subTotal*1;
            postGateway({
                url: '/customerApp/cart/getFreeItemByAmount',
                method: 'GET',
                data: {
                    merchantId: this.merchantId,
                    total: totalPrice
                }
            }).then(res=>{

                console.log(res.data, '获取freeItem 回参内容')
                
                let cardFreeItemIds = this.cartList.filter(x=>x.offId).map(x=>x.offId*1);
                let foodResults = res.data.foodResults;

                console.log(cardFreeItemIds,'cardFreeItemIds',res.data.offId)

                if(res.data.freeFlag == 1 && !cardFreeItemIds.includes(res.data.offId)) {
					this.freeItemList = foodResults.map(x=>({
						...x,
						offId: res.data.offId,
                        overFlag: res.data.overFlag
					}))

					// this.freeItemList  = [this.freeItemList[0]]
					// this.freeItemModal = true;
                    console.log(this.freeItemList,'this.freeItemList')
                    this.$refs.menuBox.setFreeItemModal([this.freeItemList[0]]);
				}
            })
            .catch(()=>{
                
            })
        },
        // 获取当前选择购物车内容的价格
        getCartPrice() {  // 不需要登陆
            postGateway({
                url: '/customerApp/cart/getBatMerchantCart',
                data: {
                    merchantId: this.merchantId,
                    foodCartList: this.cartList,
                    transType: this.transType,
                    clientId: this.clientId || null,
                    requestFrom: 'web',
                    couponCode: ''
                }
            }).then(res=>{
                console.log(res.data,'购物车价格')
                this.cartTotalPrice = res.data.subTotal;
                // 查询当前是否有 FreeItem 可以送
                this.getFreeItemInfo(res.data)
                // 校验当前价格是否需要删除 FreeItem
                this.checkFreeItemStatus();
            })
            .catch(err=>{
                this.$message.warning(err.msg || 'Net Error');
                console.log(err, '获取当前购物车价格失败')
            })
        },
        // 检测当前的购物车价格是否
        checkFreeItemStatus() {
            // 查询不符合价格限制的 FreeItem 内容
            let targetArr = this.cartList.filter(x=>{
                return x.offId && x.offAmountLimit > this.cartTotalPrice
            });

            if(targetArr.length) {
                let index = this.cartList.findIndex(x=>x.offId === targetArr[0].offId) 
                this.cartList.splice(index,1)
            }
        },
        // 加载商家的方法
        initMerchant() {
            let slug = this.$route.params.pathMatch;
            this.slug = slug;
            if(this.slug.includes('/')) {  //处理 ssr 默认访问会带一个 /
                this.slug = this.slug.replace(/\//g,'')
            }
            console.log(this.slug,'slug----')

            if(this.slug.includes('m.html')) {  //处理 ssr 默认访问会带一个 /
                location.href = 'https://www.alleatapp.com/m'
                return
            }
            if(this.slug.includes('merchant.html')) {  //处理 ssr 默认访问会带一个 /
                location.href = 'https://www.alleatapp.com/merchant'
                return
            }

            this.pageLoadingOpen();
            this.getMerchantId(this.slug).then(()=>{
                
                // this.getAllMenu(); TODO暂时不用
                this.getMerchantInfo();
                this.getCategoryList();  // 获取菜单分类
                if(!localStorage.getItem('token')) {
                    return
                }
                // 获取缓存购物车
                this.cartList = getCart(this.merchantId);
                // this.getCartInfo(); // 获取用户购物车
            })
            .catch(()=>{
                this.pageLoadingClose();
                this.$router.push({
                    path: '/404'
                })
            })
        }, 
        // 同步菜品数量
        reSetMenuFoodCount(food) {
            this.categoryList.forEach(ele=>{
                if(ele.catId == food.categoryId) {
                    ele.foodList.forEach(fd=>{
                        if(fd.itemId == food.itemId) {
                            fd.qty = food.qty;
                        }
                    })
                }
                // 同时处理是否是 recommend 
                if(ele.catId == 0) {
                    ele.foodList.forEach(fd=>{
                        if(fd.itemId == food.itemId) {
                            fd.qty = food.qty;
                        }
                    })
                }
            })
        },
        // 加入购物车
        addCart(food, foodType) {
            let cartIds = this.cartList.map(x=>x.itemId)

            console.log(food,'foodDetails');

            // 同步菜品数量
            this.reSetMenuFoodCount(food);

            if(food.qty == 0) {
                let ind = cartIds.indexOf(food.itemId);
                ind == -1?'':this.cartList.splice(ind, 1)
                return
            }

            if(cartIds.includes(food.itemId) && foodType!='sku') {
                let ind = cartIds.indexOf(food.itemId);
                this.cartList[ind].qty = food.qty;
            } else {

                // 如果是添加 freeItem 需要先判断 overFlag 是否叠加 如果不叠加的话 去掉之前的FreeItem
                if(food.offId && food.overFlag == 0) {
                    let ind = this.cartList.findIndex(x=>x.offId);
                    ind == -1?'':this.cartList.splice(ind, 1)
                }

                // 有 addon 的菜
                this.cartList.push(food)
            }
            console.log(food,foodType, '加入购物车内容')
        },
        // 获取用户在该商家下的购物车内容
        getCartInfo() {
            postGateway({
                url: '/customerWeb/cart/getMerchantCat',
                method: 'GET',
                data: {
                    merchantId: this.merchantId,
                    clientId: this.clientId,
                }
            }).then(res=>{
                this.cartList = res.data;
                console.log(res,'购物车内容')
            })
            .catch(err=>{
                console.log(err,'获取购物车报错')
            })
        },
        getMenuList(index){
            if(this.categoryList[index].foodList.length) return   //提前加载或者指定加载已经有数据了不重新获取
            postGateway({ // 根据 类型id查找食品
                url: '/customerWeb/merchantDetail/getMerchantFood',
                method: 'GET',
                data: {
                    merchantId: this.merchantId,
                    catId: this.categoryList[index].catId,
                    keyword: this.keyword
                }
            }).then((res) => {
                if(res.code == 200){
                    this.categoryList[index].foodList = res.data.map(x=>({
                        ...x,
                        qty: 0
                    }))
                    this.categoryList[index].loadDone = true;
                }
            })
        },
        autoLoadFoodList() {  // 500 毫秒加载一个菜单内容
            let i = 1;
            this.foodTimer = setInterval(() => {
                if(i > this.categoryList.length-1) {
                    clearInterval(this.foodTimer)
                    return
                }
                this.getMenuList(i);
                i++
            }, 500);
        },
        // 获取 菜单分类
        getCategoryList() {
            postGateway({
                url: '/customerWeb/merchantDetail/getMerchantCat',
                method: 'GET',
                data: {
                    merchantId: this.merchantId
                }
            }).then(res=>{
                this.categoryList = res.data.map(x=>({
                    ...x,
                    foodList: []
                }))
                this.nowCateId = this.categoryList[0].catId
                this.autoLoadFoodList();

                // 监听每个 category dom 
                this.$nextTick(()=>{
                this.categoryList.forEach(ele=>{
                        this.observer.observe(document.querySelector('#catId-'+ele.catId));
                    })
                })
            })
        },
        focusMerchant() {
            let clientId = localStorage.getItem('clientId');
            if(!clientId) {
                this.$message.info('You Need Login First.')
                return
            }
            if(!this.merInfo.isFavorite) {
                postGateway({
                    url: '/customer/myfavorite/addFavorite',
                    data: {
                        merchantId: this.merchantId * 1,
                        clientId: this.clientId * 1
                    }
                }).then(()=>{
                    this.merInfo.isFavorite = this.merInfo.isFavorite?0:1;
                    this.$message.info('Success!')
                }).catch()
            } else {
                postGateway({
                    url: '/customer/myfavorite/delete',
                    data: {
                        merchantId: this.merchantId * 1,
                        clientId: this.clientId * 1
                    }
                }).then(()=>{
                    this.merInfo.isFavorite = this.merInfo.isFavorite?0:1;
                    this.$message.info('Success!')
                }).catch()
            }
            
        },
        getMerchantId(slug) {
            console.log(slug,'请求参数的 slug')
            return postGateway({
                url: "/customerWeb/merchantDetail/getMerchantBySlug",
                method: "GET",
                data: {
                    slug
                }
            }).then(res=>{
                this.merchantId = res.data*1
            })
        },
        getMerchantInfo() {
            let param = {
                merchantId: this.merchantId,
                type: this.transType == 'delivery'?1:this.transType == 'pickup'?2:this.transType == 'dinein'?3: 0,
            }
            postGateway({
                url: '/customerApp/merchantDetail/getMerchantDetail',
                method: 'GET',
                data: param
            })
            .then(res=>{
                this.merInfo = res.data
                // 正常折扣信息
                if(!this.merInfo.merchantOffs) {
                    this.merInfo.merchantOffs = []
                }
                // freeItem 信息
                if(!this.merInfo.freeItemoffs) {
                    this.merInfo.freeItemoffs = []
                }
                this.initPickTime();
                setTimeout(() => {
                    this.pageLoadingClose();
                    if(!this.transTypeBeingChosed) {
                        this.$nextTick(()=>{
                            // this.showModal = true;  // 暂时不需要选择
                        })
                    }
                    this.transTypeBeingChosed = true;
                }, 2000);

                let { delivery, dinein, pickup } = res.data;
                if(!res.data[this.transType] && !this.transTypeBeingChosed) {
                    if(pickup) {
                        this.transType = 'pickup'
                    }else if(dinein) {
                        this.transType = 'dinein'
                    } else if(delivery) {
                        this.transType = 'delivery'
                    }
                }

                this.transTypeList = [];
                if(delivery) {
                    this.transTypeList.push({
                        name: 'Delivery',
                        value: 'delivery',
                        active: false
                    })
                }
                if(pickup) {
                    this.transTypeList.push({
                        name: 'Pick-up',
                        value: 'pickup',
                        active: false
                    })
                }
                if(dinein) {
                    this.transTypeList.push({
                        name: 'Dine-in',
                        value: 'dinein',
                        active: false
                    })
                }
                
                console.log('执行了这里啊')

                let { restaurantName, seoMenu, seoMenuKeywords, seoMenuMeta } = this.merInfo;
                document.title =  seoMenu || (seoMenuKeywords == null ? restaurantName : seoMenuKeywords);
                let head = document.getElementsByTagName('head');
                let meta = document.createElement('meta');
                document.querySelector('meta[name="keywords"]').setAttribute('content', seoMenuKeywords == null ? restaurantName : seoMenuKeywords)
                document.querySelector('meta[name="description"]').setAttribute('content', seoMenuMeta== null ? restaurantName : seoMenuMeta)
                head[0].appendChild(meta)

                // 设置link
                if(document.querySelector('link[rel=canonical]')) {
                    document.querySelector('link[rel=canonical]').href = `https://www.alleatapp.com/menu-${this.slug}`
                }
                
                
            })
            .catch((err)=>{
                console.log(err,'报错 err')
                setTimeout(() => {
                    this.pageLoadingClose();
                }, 2000);
                this.$router.push({
                    path: '/404'
                })
            })
        },
        timeToNum(str,judge=0) {
			let result = str.split(':').join('') * 1;
			let orTime = this.merInfo.orderTime * 1;
			
			// 跨天营业时间不需要计算orderTime
			let across = false;
			if(result === 0 && [1,2].includes(judge)) {
				across = true
			}
			
			// 需要处理 开店后时间
			if(judge && str) {
				let shi = str.split(':')[0]*1;
				let fen = str.split(':')[1]*1 + (across?0:orTime);
				shi = fen >= 60 ? shi+1 : shi;
				fen = (fen % 60)*1>=10?(fen % 60):(fen % 60) + '0';
				result = (shi +''+ fen) *1;
			}
			return result
		},
        timeToNum2(str,judge=0) {
			let result = str.split(':').join('') * 1;
			let orTime = this.merInfo.orderDineTime * 1 || 0;
			
			// 跨天营业时间不需要计算orderTime
			let across = false;
			if(result === 0 && [1,2].includes(judge)) {
				across = true
			}
			
			// 需要处理 开店后时间
			if(judge && str) {
				let shi = str.split(':')[0]*1;
				let fen = str.split(':')[1]*1 + (across?0:orTime);
				shi = fen >= 60 ? shi+1 : shi;
				fen = (fen % 60)*1>=10?(fen % 60):(fen % 60) + '0';
				result = (shi +''+ fen) *1;
			}
			return result
		},
        // 获取明天之后的工作时间 并且赋值给时间选择器
		getTimeFlag(timeList,num, type='normal') {
			let map = new Map();
			// 营业时间 一周的完整营业时间 选择
			timeList.forEach(ele1=>{
				let arr = [];
				pickerList.normal.forEach(ele=>{
					if((ele1.startTime1 && this.timeToNum(ele) >= this.timeToNum(ele1.startTime1,1) && this.timeToNum(ele) <= this.timeToNum(ele1.endTime1))
					||  (ele1.startTime2 && this.timeToNum(ele) >= this.timeToNum(ele1.startTime2,(ele1.startTime1?0:2)) && this.timeToNum(ele) <= this.timeToNum(ele1.endTime2))) {
						arr.push({
							label: ele,
							value: ele
						})
					}
				})
				map.set(ele1.weekday,arr)
			})
			
			// map 是个完整的每周工作时间
			// 只需要计算 后天开始 - 结束日期-2  的时间

			// 今天周几
			let todayDay = new Date().getDay()==0?7:new Date().getDay();
			for (let i=2; i<num+1; i++) {
                if(type == 'booking') {
                    this.tableBookMultiSelector.push({
                        value: this.$moment().add(i, 'days').format('L'),
                        label: this.$moment().add(i, 'days').format('L').replace(/\//g,'-') ,
                        children: map.get(((todayDay + i) % 7 == 0?7:(todayDay + i) % 7))  // 把周几传进来就可以获取工作时间了
                    })
                } else {
                    this.multiSelector.push({
                        value: this.$moment().add(i, 'days').format('L'),
                        label: this.$moment().add(i, 'days').format('L').replace(/\//g,'-') ,
                        children: map.get(((todayDay + i) % 7 == 0?7:(todayDay + i) % 7))  // 把周几传进来就可以获取工作时间了
                    })
                }
				
			}	
							
		},
        // 初始化选择器
        getDefaultTime() {
            this.multiSelector = [
				{
					value: '',
					label: 'Today',
					children: [
                        {
                            value: this.transType == 'pickup'?'':'asap',
							label:  this.transType == 'pickup'?'':'As soon as possible'
						}
					]
				},
				{
					value: '',
					label: 'Tomorrow',
					children: []
				},
			];

            this.tableBookMultiSelector = [
                {
					value: '',
					label: 'Today',
					children: []
				},
				{
					value: '',
					label: 'Tomorrow',
					children: []
				},
            ];
        },
        initTableBookTime(list = []) {  // tableBooking 选择器时间
            // 计算tableDine 时间
			let timeList = list;
			
			// 计算周几
			let timeFlag1 = new Date().getDay()==0?7:new Date().getDay();  //  0-6
			let timeFlag2 = ((timeFlag1 + 1) % 7 == 0?7:(timeFlag1 + 1) % 7);
			// let timeFlag3 = ((timeFlag1 + 2) % 7 == 0?7:(timeFlag1 + 2) % 7);
			
			// 关店时间限制
			let limit = {
				t1: null,
				t2: null,
			};
			timeList.forEach(ele=>{
				if(ele.weekday == timeFlag1) {
					limit.t1 = ele;
				}
				if(ele.weekday == timeFlag2) {
					limit.t2 = ele;
				}
			}) 	
			
			let limitTime = this.$moment(new Date().valueOf() + (this.merInfo.orderDineTime*60000 || 0)).format('HH:mm').split(':');
			
			// 目前仅处理3天的 下单时间
			this.tableBookMultiSelector[0].value = this.$moment().format('L')
			this.tableBookMultiSelector[1].value = this.$moment().add(1, 'days').format('L') // 明天					
			
            // TODO 待优化 时间前后颠倒会有问题
			pickerList.normal.forEach(ele=>{
				// 今天只能选择 营业时间 45分钟以后
				if(  (ele.split(':')[0]*1 > limitTime[0]*1 || (ele.split(':')[0] == limitTime[0] && ele.split(':')[1]*1 > limitTime[1]*1)) && limitTime[0] !== '00') {
					
					if((limit.t1.startTime1 && this.timeToNum2(ele) >= this.timeToNum2(limit.t1.startTime1,1) && this.timeToNum2(ele) <= this.timeToNum2(limit.t1.endTime1))
                        || (limit.t1.startTime2 && this.timeToNum2(ele) >= this.timeToNum2(limit.t1.startTime2,(limit.t1.startTime1?0:2)) && this.timeToNum2(ele) <= this.timeToNum2(limit.t1.endTime2))) {
						this.tableBookMultiSelector[0].children.push({
							label: ele,
							value: ele,
						})
					}
				}
				
				// 明天
				if((limit.t2.startTime1 && this.timeToNum2(ele) >= this.timeToNum2(limit.t2.startTime1,1) && this.timeToNum2(ele) <= this.timeToNum2(limit.t2.endTime1))
				||  (limit.t2.startTime2 && this.timeToNum2(ele) >= this.timeToNum2(limit.t2.startTime2,2,(limit.t2.startTime1?0:2)) && this.timeToNum2(ele) <= this.timeToNum2(limit.t2.endTime2))) {
					this.tableBookMultiSelector[1].children.push({
						label: ele,
						value: ele
					})
				}
				
			})
			
			// 处理后天之后的时间
			this.getTimeFlag(timeList,this.merInfo.timesDay*1, 'booking')
            let nowTime = this.$moment(new Date().valueOf()).format('HH:mm');
				
			if((limit.t1.startTime1 && this.timeToNum2(nowTime) < this.timeToNum2(limit.t1.startTime1) || ( limit.t1.endTime1 && this.timeToNum2(nowTime) > this.timeToNum2(limit.t1.endTime1)
                && limit.t1.startTime2 && this.timeToNum2(nowTime) < this.timeToNum2(limit.t1.startTime2)) || (limit.t1.endTime2 && this.timeToNum2(nowTime) > this.timeToNum2(limit.t1.endTime2)))) {
				this.tableBookMultiSelector[0].children[0] = {
					label: '',
					value: ''
				}
				
				if(this.tableBookMultiSelector[0].children.length > 1) { // 有时间 没有asap 的情况 第一行去掉
					this.tableBookMultiSelector[0].children.shift();
				}
			}
			
			if(this.tableBookMultiSelector[0].children.length == 1) {  // 只有一个 说明今天没得送了
				try {
                    this.tableBookMultiSelector[0].children = [
                        {
                            label: 'Shop Closed',
                            value: ''
                        }
                    ];
                } catch (error) {
                    console.log(error,'error')
                }
			}
			
			if(this.merInfo.timesDay*1 == 0) {  // 只能预定当天的
				this.tableBookMultiSelector.pop()
			}

            if(this.merchantId == 2195) {
                this.tableBookMultiSelector.forEach((ele, ind)=>{
                    let arr = [];
                    ele.children.forEach((e, i)=>{
                        if(ind == 0 && ((i-1%3) == 0 || i==0)) {
                            arr.push(e)
                        } else if(ind != 0 && i%3 == 0) {
                            arr.push(e)
                        }
                    })
                    ele.children = arr;
                })
            }

        },
        // 初始化 选择时间 List  只用计算 dinein 的
		initPickTime() {
            postGateway({
                url: '/merchant/merchantOperateTime/getList',
                method: 'GET',
                data: {
                    merchantId: this.merchantId
                }
            })
            .then(({data})=>{
                this.getDefaultTime();
				let res = data;
                
				// 获取每周的开店时间
				let timeList = this.transType?res[this.transType]:(res&&res.dinein)?res.dinein:[];
				
				// 计算周几
				let timeFlag1 = new Date().getDay()==0?7:new Date().getDay();  //  0-6
				let timeFlag2 = ((timeFlag1 + 1) % 7 == 0?7:(timeFlag1 + 1) % 7);
				let timeFlag3 = ((timeFlag1 + 2) % 7 == 0?7:(timeFlag1 + 2) % 7);
				
				console.log(res.dinein,'营业时间',timeFlag1,timeFlag2,timeFlag3,this.transType);
                
				// 关店时间限制
				let limit = {
					t1: null,
					t2: null,
				};

				timeList.forEach(ele=>{
					if(ele.weekday == timeFlag1) {
						limit.t1 = ele;
                        try {
                            this.todayOpenTime = ele.startTime1 || ele.startTime2;
                        } catch (error) {
                            this.todayOpenTime = ele.startTime1 || ele.startTime2;
                        }
					}
					if(ele.weekday == timeFlag2) {
						limit.t2 = ele;
					}
				}) 	
				
				let limitTime = this.$moment(new Date().valueOf() + this.merInfo.orderTime*60000).format('HH:mm').split(':');
				
				// 目前仅处理3天的 下单时间
				this.multiSelector[0].value = this.$moment().format('L')
				this.multiSelector[1].value = this.$moment().add(1, 'days').format('L') // 明天					
				
                // TODO 待优化 时间前后颠倒会有问题
				pickerList.normal.forEach(ele=>{
					// 今天只能选择 营业时间 45分钟以后
					if(  (ele.split(':')[0]*1 > limitTime[0]*1 || (ele.split(':')[0] == limitTime[0] && ele.split(':')[1]*1 > limitTime[1]*1)) && limitTime[0] !== '00') {
						
						if((limit.t1.startTime1 && this.timeToNum(ele) >= this.timeToNum(limit.t1.startTime1,1) && this.timeToNum(ele) <= this.timeToNum(limit.t1.endTime1))
                            || (limit.t1.startTime2 && this.timeToNum(ele) >= this.timeToNum(limit.t1.startTime2,(limit.t1.startTime1?0:2)) && this.timeToNum(ele) <= this.timeToNum(limit.t1.endTime2))) {
							this.multiSelector[0].children.push({
								label: ele,
								value: ele,
							})
						}
					}
					
					// 明天
					if((limit.t2.startTime1 && this.timeToNum(ele) >= this.timeToNum(limit.t2.startTime1,1) && this.timeToNum(ele) <= this.timeToNum(limit.t2.endTime1))
					||  (limit.t2.startTime2 && this.timeToNum(ele) >= this.timeToNum(limit.t2.startTime2,2,(limit.t2.startTime1?0:2)) && this.timeToNum(ele) <= this.timeToNum(limit.t2.endTime2))) {
						this.multiSelector[1].children.push({
							label: ele,
							value: ele
						})
					}
					
				})
				
				// 处理后天之后的时间
				this.getTimeFlag(timeList,this.merInfo.timesDay*1)

                let nowTime = this.$moment(new Date().valueOf()).format('HH:mm');
					
				if((limit.t1.startTime1 && this.timeToNum(nowTime) < this.timeToNum(limit.t1.startTime1) || ( limit.t1.endTime1 && this.timeToNum(nowTime) > this.timeToNum(limit.t1.endTime1)
                    && limit.t1.startTime2 && this.timeToNum(nowTime) < this.timeToNum(limit.t1.startTime2)) || (limit.t1.endTime2 && this.timeToNum(nowTime) > this.timeToNum(limit.t1.endTime2))) || this.transType === 'pickup') {
					this.multiSelector[0].children[0] = {
						label: '',
						value: ''
					}
					
					if(this.multiSelector[0].children.length > 1) { // 有时间 没有asap 的情况 第一行去掉
						this.multiSelector[0].children.shift();
					}

                    this.$nextTick(()=>{
                        this.$refs.cartBoxDom.setBookTime(''); // 没有asap 了
                    })
					
				}
				
				if(this.multiSelector[0].children.length == 1) {  // 只有一个 说明今天没得送了
					try {
                        this.multiSelector[0].children = [
                            {
                                label: 'Shop Closed',
                                value: ''
                            }
                        ];
                        this.$nextTick(()=>{
                            this.$refs.cartBoxDom.setBookTime(''); // 没有asap 了
                        })
                    } catch (error) {
                        console.log(error,'error')
                    }
				}
				
				if(this.merInfo.timesDay*1 == 0) {  // 只能预定当天的
					this.multiSelector.pop()
				}

                if(this.merchantId == 2195) {
                    this.multiSelector.forEach((ele, ind)=>{
                        let arr = [];
                        ele.children.forEach((e, i)=>{
                            if(ind == 0 && ((i-1%3) == 0 || i==0)) {
                                arr.push(e)
                            } else if(ind != 0 && i%3 == 0) {
                                arr.push(e)
                            }
                        })
                        ele.children = arr;
                    })
                }

                // 处理 tableBooing 时间问题
                if(this.merInfo.dinein && res.dinein) {
                    this.initTableBookTime([...res.dinein] || []);
                }
				
				console.log(this.multiSelector,'处理后的结果下单时间选择')
			})
			
		},
        // 恢复默认数据
        reStartData() {
            this.merInfo = {
                restaurantName: '',
                merchantId: '',
                merchantLogo: '',
                tasteRating: '',
                merchantAddr: '',
                deliveryDistance: ''
            },
            this.categoryList = []
            this.foodList = []
            this.nowCateId = ''
            this.keyword = ''
            this.cartList = []
            this.foodTimer = null
            this.multiSelector = [
				{
					value: '',
					label: 'Today',
					children: [
						{
							label: '',
							value: ''
						}
					]
				},
				{
					value: '',
					label: 'Tomorrow',
					children: []
				},
			]
        },
    }
}
</script>

<style lang="scss" scoped>
.menu {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    font-family: "Source Sans Pro", sans-serif;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    background: #f5f5f5;
    .cover-mask {
        height: 100%;
        width: 100%;
        z-index: 20;
        background: rgba($color: #000000, $alpha: 0.8);
        top: 0;
        left: 0;
        position: absolute;
    }
    .header {
        width: 100%;
        background-image: url('../assets/images/notfoundBg.png');
        background-size: cover;
        background-position: center;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .match-box {
        height: 520px;
        display: flex;
        flex-direction: column;
        position: relative;
        .break-box {
            flex: 1;
            box-sizing: border-box;
            padding: 48px 4%;

            .break {
                height: 380px;
                height: 44px;
                display: inline-flex;
                align-items: center;
                padding: 20px;
                border-radius: 22px;
                background-color: #0A191E;
                color: #FFFFFF;
                .el-icon-back {
                    color: #2680ED;
                    font-size: 22px;
                    margin-right: 10px;
                }
                :deep(.el-breadcrumb__inner) {
                    color: #fff;
                }
                :deep(.el-breadcrumb__separator) {
                    color: #fff;
                }
            }
        }
        .mer-box {
            height: 190px;
            padding: 0 4%;
            display: flex;
            // flex-direction: row;
            flex-direction: row-reverse;
            position: relative;
            z-index: 20;
            // .info-box {
            //     flex: 1;
            //     display: flex;
            //     flex-direction: row;
            //     padding: 20px;
            //     // background-color: rgba($color: #000000, $alpha: 0.8);
            //     // border-radius: 8px 8px 0 0;
            //     background-color: #fff;
            //     border-radius: 30px;
            //     top: 90px;
            //     .l-box, .r-box {
            //         display: flex;
            //         flex-direction: column;

            //         .img-block {
            //             display: flex;
            //             height: 100px;
            //             width: 100px;
            //             // border-radius: 8px;
            //             border-radius: 30px;
            //             overflow: hidden;
            //             margin-bottom: 14px;
            //             background-color: #e9e9e9;
            //             background-size: 100% 100%;
            //             background-position: left;
            //             img {
            //                 height: 100px;
            //                 width: 100px;
            //             }
            //         }
            //         .status-tag {
            //             padding: 5px 20px;
            //             font-weight: 700;
            //             background-color: #428600;
            //             color: #fff;
            //             font-size: 16px;
            //             // border-radius: 4px;
            //             border-radius: 30px;
            //         }
                    
            //         .status-tag-close {
            //             background: #E9E9E9;
            //             color: #939393;
            //         }
            //         .title {
            //             position: relative;
            //             top: -2px;
            //             font-size: 60px;
            //             color: #fff;
            //             font-weight: 700;
            //             margin: 0;
            //             height: 66px;
            //             margin-bottom: 10px;
            //             white-space: nowrap;
            //         }
            //         .info-item {
            //             color: #fff;
            //             font-weight: 400;
            //             display: flex;
            //             align-items: center;
            //             font-size: 17px;
            //             margin-bottom: 6px;
            //             :deep(.el-rate__icon) {
            //                 position: relative;
            //                 margin-top: -2px;
            //                 margin-right: -2px;
            //                 font-size: 20px;
            //             }
            //             span {
            //                 margin-left: 6px;
            //             }
            //             i {
            //                 color: #C2F93A;
            //                 font-size: 17px;
            //             }
            //             .btn {
            //                 position: absolute;
            //                 right: 0;
            //                 bottom: 12px;
            //                 margin-right: 10px;
            //                 max-height: 40px;
            //                 padding: 12px;
            //                 border-radius: 4px;
            //                 color: #0A191E;
            //                 font-size: 15px;
            //                 display: inline-flex;
            //                 align-items: center;
            //                 border-radius: 6px;
            //                 background-color: #fff;
            //                 box-shadow: 0px 2px 5px rgba($color: #000000, $alpha: 0.5);
            //                 white-space: nowrap;
            //                 cursor: pointer;
            //                 font-weight: 600;
            //                 transition: all ease-in-out .2s;
            //                 &:hover {
            //                     background-color: #dedcdc;
            //                 }
            //                 i {
            //                     font-size: 20px;
            //                     margin-right: 5px;
            //                 }
            //                 img {
            //                     height: 20px;
            //                     width: 20px;
            //                 }
            //             }
            //         }
            //     }
            //     .l-box {
            //         width: 100px;
            //         align-items: center;
            //         margin-right: 10px;
            //     }
            // }
        }
        .menu-tag-img {
            width: 430px;
            position: absolute;
            bottom: 0;
            right: 15%;
        }
    }
    .card-box {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 35px;
        background-color: #fff;
        border-radius: 30px;
        margin-top: -120px;
        margin-left: -300px;
        width: 800px;
        box-shadow: 0px 5px 8px #8F9698;
        position: relative;
        overflow: hidden;
        .card-box-mask {
            position: absolute;
            display: flex;
            height: 100%;
            width: 100%;
            top: 0px;
            left: 0px;
            background-color: #fff;
            padding: 20px;
            z-index: 100;
        }
    }
    .info-box {
            flex: 1;
            display: flex;
            flex-direction: row;
            .l-box, .r-box {
                display: flex;
                flex-direction: column;
                position: relative;

                .img-block {
                    display: flex;
                    // height: 100px;
                    // width: 100px;
                    width: 130px;
                    height: 130px;
                    // border-radius: 8px;
                    border-radius: 45px;
                    margin-right: 15px;
                    overflow: hidden;
                    margin-bottom: 14px;
                    background-color: #e9e9e9;
                    background-size: 100% 100%;
                    background-position: left;
                    img {
                        // height: 100px;
                        // width: 100px;
                        height: 130px;
                        width: 130px;
                    }
                }
                .status-tag {
                    padding: 5px 20px;
                    font-weight: 700;
                    background-color: #428600;
                    color: #fff;
                    font-size: 20px;
                    border-radius: 30px;
                    margin-top: 5px;
                    font-size: 15px;
                }
                    
                .status-tag-close {
                    background: #E9E9E9;
                    color: #939393;
                }
                .status-preorder {
                    background-color: #e9e9e9;
                    color: #0079fb;
                    font-size: 13px;
                }
                .title {
                    position: relative;
                    top: -2px;
                    // font-size: 60px;
                    font-size: 40px;
                    // color: #fff;
                    font-weight: 700;
                    margin: 0;
                    // height: 66px;
                    margin-bottom: 10px;
                    // white-space: nowrap;
                    margin-left: 23px;
                }
                .info-item {
                    // color: #fff;
                    color:#939393;
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                    font-size: 17px;
                    margin-bottom: 6px;
                    margin-left: 17px;
                    .status-tag-info {
                        font-weight: bold;
                        color: #007bf9;
                        font-size: 13px;
                        display: flex;
                        align-items: center;
                    }
                    :deep(.el-rate__icon) {
                        position: relative;
                        margin-top: -2px;
                        margin-right: -2px;
                        font-size: 20px;
                    }
                    span {
                        margin-left: 6px;
                    }
                    a {
                        color:#939393;
                    }
                    i {
                        color: #00ec7f;
                        // font-size: 17px;
                        font-size: 23px;
                        margin-right: 3px;
                        margin-left: -27px;
                    }
                    .reviews-info {
                        color:#939393;
                    }
                    .btn {
                        position: absolute;
                        right: -10px;
                        top: -10px;
                        margin-right: 0px;
                        max-height: 40px;
                        padding: 12px;
                        border-radius: 4px;
                        color: #0A191E;
                        font-size: 15px;
                        display: inline-flex;
                        align-items: center;
                        border-radius: 6px;
                        background-color: #fff;
                        box-shadow: 0px 2px 5px rgba($color: #000000, $alpha: 0.5);
                        white-space: nowrap;
                        cursor: pointer;
                        font-weight: 600;
                        transition: all ease-in-out .2s;
                        &:hover {
                            background-color: #dedcdc;
                        }
                        i {
                            font-size: 20px;
                            margin-right: 5px;
                        }
                        img {
                            height: 20px;
                            width: 20px;
                        }
                    }
                }
            }
            .l-box {
                // width: 100px;
                width: 130px;
                align-items: center;
                margin-right: 10px;
            }
    }
    .focus-btn-box {
       display: flex;
       align-items: flex-end;
       margin-bottom: 10px;
       margin-top: 10px;
       .mobile-show {
           display: none;
       }
       .btn {
        //    margin-right: 10px;
           margin-right: 30px;
           max-height: 40px;
           padding: 12px 10px;
           border-radius: 4px;
           color: #0A191E;
           font-size: 15px;
           display: inline-flex;
           align-items: center;
           border-radius: 6px;
           background-color: #fff;
           box-shadow: 0px 2px 5px rgba($color: #000000, $alpha: 0.5);
           white-space: nowrap;
           cursor: pointer;
           font-weight: 600;
           transition: all ease-in-out .2s;
           &:hover {
               background-color: #dedcdc;
           }
           i {
               font-size: 20px;
               margin-right: 5px;
           }
           img {
               height: 20px;
               width: 20px;
               margin-right: 5px;
           }
       }
    }

    .menu-main-box {
        width: 1600px;
        white-space: nowrap;
        position: relative;
        margin-top: 20px;
        .menu-bg {
            position: fixed;
            height: 400px;
            width: 400px;
            z-index: -1;
            img {
                width: 100%;
            }
        }
        .menu-bg-1 { 
            left: 2%;
            top: 300px;
        }
        .menu-bg-2 { 
            right: -90px;
            top: 400px;
        }
        .menu-bg-3 { 
            right: 20%;
            bottom: 5%;
        }
    }
    .category-box {
        float: left;
        box-sizing: border-box;
        display: inline-flex;
        justify-content: flex-end;
        width: calc(( 1600px - 700px ) / 2);
        top: -10px;
        position: -webkit-sticky;
        position: sticky;
        z-index: 20;
        padding-top: 20px;
        :deep(.off-box) { 
            border-radius: 30px;
            box-shadow: unset;

        }
        ::-webkit-scrollbar-track{
            background-color: unset;
        }
        :deep(.cate-search) {
            border-radius: 30px;
            box-shadow: unset;
        }
        :deep(.cate-item) {
            background-color: #f5f5f5;
            // border-bottom: unset;
            // border-bottom-width:10px;

        }
        .category-box-mask {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            background-color: #fff;
            z-index: 21;
            display: flex;
            align-items: center;
            justify-content: center
        }
    }
    .menu-box {
        display: inline-flex;
        flex-direction: column;
        overflow: hidden;
        width: 700px;
        :deep(.menu-title) {
            background-color: unset;
            h2 {
                background-color: #aa0bc0;
                border-radius: 30px;
                box-shadow: unset;
            }
        }
        :deep(.cate-box) {
            // background-color: unset;
            box-shadow: unset;
            h2 {
                font-size: 30px;
            }
            .food-header {
                background-color: unset;
                border-top: unset;
            }
            .food-item {
                background-color: unset;
                border-bottom: unset;
                .item-name {
                    font-size: 16px;
                }
                .item-order {
                    // display: none;
                }
            }
        }
    }
    .cart-box {
        float: right;
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-start;
        width: calc(( 1600px - 700px ) / 2);
        position: -webkit-sticky;
        position: sticky;
        z-index: 20;
        top: -10px;
        padding-top: 20px;
        margin-top: -88px;
        :deep(.view-info) {
            box-shadow: unset;
        }
        :deep(.book-btn,.cart-title) {
            box-shadow: unset;
        }
        :deep(.cart-title) {
            box-shadow: unset;
            border-radius:15px 15px 0px 0px;
            i {
                margin: 0px 50px;
            }
        }
        :deep(.main-cart) {
            box-shadow: unset;
            margin-top: 0px;
            border-radius: 0px 0px 15px 15px;
            .type-box {
                .type-btn {
                    box-shadow: unset;
                    border-radius: 30px;
                }
            }
            .time-btn {
                border-radius: 30px;
                box-shadow: unset;
                border:solid 1px rgba(0, 0, 0, 0.25);
            }
            .cart-content {
                .cart-item {
                    .add-btn {
                        border:solid 1px #e3e3e3;
                    }
                    .name {
                        margin-left: 8px;
                    }
                }
            }
        }
        :deep(.order-btn) {
            display: flex;
            flex-direction: row-reverse;
            box-shadow: unset;
            .price-box {
                margin-right: -31px;
                background-color: #000;
                color: #fff;
            }
        }
        :deep(.limit-box) {
            border-radius: 30px;
        }
        
    }
    .clear {
        clear: both;
    }

    :deep(.el-backtop) {
        z-index: 199;
    }

    .transtype-modal {
        .modal-title {
            text-align: center;
            font-weight: 600;
            color: #000;
            font-size: 24px;
            text-align: center;
            white-space: pre-wrap;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: -15px;

            .gray-line {
                height: 4px;
                width: 90px;
                background: #d9d9d9;
                margin-bottom: 6px;
            }
        }

        :deep(.el-dialog__body) {
            padding-right: 0;
            padding-bottom: 0;
        }
        
        .modal-footer  {
            display: flex;
            justify-content: center;
            margin-top: -15px;
            button {
                height: 40px;
                width: 80px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: bold;
                color: #fff;
                border-radius: 6px;
            }
        }
        .trans-modal {
            display: flex;
        }
        .modal-content {
            max-height: 60vh;
            overflow: auto;
            margin-right: 4px;
            &::-webkit-scrollbar {
                width: 6px;
            }
            .price-title {
                color: #09CA6A;
                font-size: 16px;
                text-align: left;
                margin-bottom: 15px;
            }
            .dash-row {
                margin: 15px 0;
                border-top: 1px dashed #cacaca
            }
            .addon-title {
                color: #aa0bc0;
                margin-bottom: 15px;
                margin-top: 10px;
            }
            .price-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 15px;
                padding: 6px 10px;
                border-radius: 4px;
                border: 2px solid #f4f3f3;
                margin-bottom: 10px;
                color: #000;
                cursor: pointer;
            }
            .price-item-active {
                color: #aa0bc0;
                border: 2px solid #aa0bc0;
                background-color: #f9eafc;
            }
            .addon-box {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                .price-item-addon {
                    display: inline-flex;
                    justify-content: flex-start;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 15px;
                    padding: 6px 10px;
                    border-radius: 4px;
                    border: 2px solid #f4f3f3;
                    margin-bottom: 10px;
                    color: #000;
                    cursor: pointer;
                    margin-right: 10px;
                }
                .price-item-active {
                    color: #aa0bc0;
                    border: 2px solid #aa0bc0;
                    background-color: #f9eafc;
                }
            }
            .trans-drawer-box {
                padding: 0 20px;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                justify-content: center;
                ul {
                    .trans-btn {
                        height: 40px;
                        color: #111;
                        font-size: 18px;
                        margin: 0 auto 12px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: bold;
                        border-radius: 2px;
                        white-space: nowrap;
                        padding: 0 16px;
                        cursor: pointer;
                        background: #d9d9d9;
                        .name {
                            margin-right: 10px;
                            font-size: 18px;
                            white-space: nowrap;
                            flex: 1;
                        }
                        .name-icon {
                            height: 20px;
                            width: 20px;
                        }
                        .desc {
                            font-size: 12px;
                            font-weight: 400;
                            color: #7a7a7a;
                        }
                    }
                    .active {
                        font-weight: bold;
                        color: #fff;
                        position: relative;
                        background: #09CA6A;
                    }
                }
            }

            .img-box {
                padding-left: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                .color-bg {
                    display: none;
                    // position: absolute;
                    // top: 50%;
                    // left: 50%;
                    // transform: translateX(-50%) translateY(-50%);
                    // height: 140px;
                    // width: 140px;
                    // border-radius: 70px;
                    // background: #d7c3ff;
                }
                img {
                    width: 100%;
                }
            }
        }
    }

}

// 屏幕尺寸兼容 <1600
@media screen and (min-width:1300px) and (max-width: 1600px) {
    .menu {
        .header {
            .match-box {
                .mer-box {
                    .focus-btn-box {
                        margin-right: 45px;
                    }
                }
            }
        }
        .menu-main-box {
            width: 1400px;
            
            .category-box {
                width: 400px;
            }
            .menu-box {
                width: 600px;
            }
            .cart-box {
                width: 400px;
            }
        }
    }
}

// 手机相关布局
@media screen and (max-width: 950px) {
    .menu {
        // background: #f7f7f7;
        background: #e7e6e6;
        font-family: "Source Sans Pro", sans-serif;
        .menu-bg {
            display: none;
        }
        .focus-btn-box {
            flex-wrap: no-wrap;
            width: 100%;
            padding-left: 9px;
            // margin-top: 20px;
            margin:15px 0px;
            justify-content: center;
            .btn {
                margin-right: 10px;
                margin-left: 0;
                font-weight: bold;
                font-size: 13px;
                border-radius: 30px;
                box-shadow: unset;
            }
        }
        .header {
            width: 100vw;
            .break-box {
                display: none;
            }
            .match-box {
                min-height: 210px;
                height: 210px;
                width: 100%;
                .menu-tag-img {
                    display: none;
                }
            }
            .mer-box {
                // padding: 15px;
                // margin-top: 16px;
                padding: 8px;
                margin-top: 96px;
                width: 100%;
                height: auto;
                flex-direction: column;
                
                .focus-btn-box {
                    margin-top: 20px;
                    flex-wrap: wrap;
                    .btn {
                        margin-right: 10px;
                        margin-left: 0;
                        // margin-bottom: 10px;
                        font-weight: bold;
                    }
                }
            }
        }
        .card-box {
            width: 95%;
            margin-top: -30px;
            padding: 15px;
            margin-left: 0px;
            box-shadow: unset;
        }
        .info-box {
            // border-radius: 8px;
            
            .l-box {
                align-items: flex-start;
                padding: 10px 0px 25px 10px; 
                margin-right: 0px;
                .status-tag {
                    padding: 5px 10px;
                    width: 90%;
                    text-align: center;
                    white-space: nowrap;
                }
                .img-block {
                    border-radius:30px;
                    width: 100px;
                    height: 100px;
                    img {
                        width: 100px;
                        height: 100px;
                    }
                }
            }
            .r-box {
                padding:20px 5px 0px 0px;
                position: relative;
                .title {
                    font-size: 20px!important;
                    white-space: pre-wrap;
                    height: auto;
                    color: #000;
                    margin-left: 3px;
                }
                .info-item {
                    font-size: 12px;
                    // color: #000;
                    margin-left:0px;
                    i {
                        margin-right:0px;
                        margin-left: 0px;
                        font-size:17px;
                    }
                    a {
                        color: #aa0bc0;
                    }
                    .reviews-info {
                        color:#aa0bc0;
                    }
                    .btn {
                        bottom: unset;
                        margin-right: 0px;
                        background: unset;
                        box-shadow: unset;
                        &:hover {
                            background-color:unset;
                        }
                    }
                }
            }
        }
        .menu-main-box {
            width: 100vw;
            display: flex;
            flex-direction: column;
            margin-top: 0px;
            .category-box,.menu-box,.cart-box {
                width: 100%;
            }
            .menu-box {
                :deep(.cate-box) {
                    border-radius: 30px;
                    h2 {
                        font-size: 20px;
                    }
                    .food-header {
                       border-top: 0.5px solid rgba(0, 0, 0, 0.2);
                       border-bottom:0.5px solid rgba(0, 0, 0, 0.2);
                       padding:10px 0px;
                    }
                    
                }
            }
            .category-box {
                justify-content: flex-start;
                height: auto;
                // padding-top: 10px;
                padding-top:0px;
                // padding-bottom: 10px;
                background: #fff;
                top: 0;
                border-top: 1px solid #ccc;
                border-bottom: 1px solid #ccc;
                // border-top: 5px solid #ccc;
                // border-bottom: 5px solid #ccc;
                :deep(.now-cate) {
                    border-radius: 10px;
                    background:#26d07c;
                }
                :deep(.cate-item) {
                    font-size: 17px;
                }
            }

            // 购物车盒子
            .cart-box {
                display: block;
                position: fixed;
                bottom: 0;
                top: unset;
                z-index: 22;
                :deep(.order-btn) {
                    .price-box {
                        margin-right: -31px;
                        background-color: #fff;
                        color: #000;
                    }
                }
                :deep(.cart-title) {
                    i {
                        margin: 0px 10px;
                    }
                }
            }
        }

    }
}
</style>